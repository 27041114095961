import axios from 'axios';
import Cookie from 'universal-cookie';
const host = process.env.REACT_APP_BACKEND;



// Create an Axios instance
const axiosInstance = axios.create({
  baseURL: host,
});




async function getTokenOrRefresh() {
    const urlParams = new URLSearchParams(window.location.search);
    const id = urlParams.get('id'); // Gets the value of 'id' from the URL query string
    const s = urlParams.get('s');   // Gets the value of 's' from the URL query string

    try {
        const res = await axiosInstance.get('/get-speech-token', {
            params: { "id": id, "s": s }
        });
        const token = res.data.token;
        const region = res.data.region;
        return { authToken: token, region: region };
    } catch (err) {
        console.error("Error fetching speech token:", err.response ? err.response.data : err.message);
        return { authToken: null, region: null, error: err.response ? err.response.data : err.message };
    }
}


// Function to get a new token for NevronixBE
async function getToken() {
  try {

    const urlParams = new URLSearchParams(window.location.search);
    const id = urlParams.get('id'); // Gets the value of 'id' from the URL query string
    const s = urlParams.get('s');   // Gets the value of 's' from the URL query string

    const response = await axiosInstance.post('/token', {}, {
      headers: {
        'x-api-secret': s
      }, 
       params: {id: id , s: s}
    });
    const { accessToken, refreshToken , set} = response.data;
    localStorage.setItem('accessToken', accessToken);
    localStorage.setItem('refreshToken', refreshToken);
    localStorage.setItem('token_set_at',new Date().toString())
    localStorage.setItem('set', JSON.stringify(set));

    return accessToken;
  } catch (error) {
    console.error('Error getting token:', error);
    throw error;
  }
}

// Function to refresh the token for Nevronix BE
async function refreshToken() {
  try {
    const refreshToken = localStorage.getItem('refreshToken');
    const response = await axiosInstance.post('/refresh-token', {}, {
      headers: {
        'x-refresh-token': refreshToken
      }
    });
    const { accessToken } = response.data;
    localStorage.setItem('accessToken', accessToken);
    return accessToken;
  } catch (error) {
    console.error('Error refreshing token:', error);
    throw error;
  }
}

// Request interceptor to include the token
axiosInstance.interceptors.request.use(async (config) => {
  let token = localStorage.getItem('accessToken');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});


// Response interceptor to handle token expiration
axiosInstance.interceptors.response.use((response) => {
  return response;
}, async (error) => {
  const originalRequest = error.config;

  if (error.response && error.response.status === 401 && error.response.data.error === 'TokenExpiredError' && !originalRequest._retry) {
    originalRequest._retry = true;

    try {
      // Get a completely new token
      const newToken = await getToken(); // Call your function to get a new token

      // Retry the original request with the new token
      originalRequest.headers.Authorization = `Bearer ${newToken}`;
      return axiosInstance(originalRequest);
    } catch (refreshError) {
      // Handle errors while getting a new token
      console.error('Error getting new token:', refreshError);
      return Promise.reject(refreshError); // Reject with the error
    }
  }

  // Reject with the original error if it's not a token expiration issue
  return Promise.reject(error);
});


export { axiosInstance, getToken, refreshToken, getTokenOrRefresh };